@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .active {
      @apply text-primary;
    }
  }

.herobgc{
    background-image: url('../src/assets/london.jpg');
}

footer{
  position:absolute;
  left:0;
  bottom:0;
  right:0;
}

.widget-container {
  height: auto; /* Adjust height if needed */
  margin: 40px auto; /* Centers the widget horizontally */
  padding: 20px;
  text-align: center;
}

/* Custom scrollbar styles */
.custom-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* Prevent body scrolling */
body.overflow-hidden {
  overflow: hidden;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Menu Drawer */
.menu-drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 75%;
  max-width: 300px;
  background: linear-gradient(to right, purple, blue, green);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1100;
}
.menu-drawer.open {
  transform: translateX(0);
}

